var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.full_loading,
          expression: "full_loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "profile_page" }
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "profile-container" }, [
            _c(
              "div",
              { staticClass: "profile-photo-info" },
              [
                _c("el-avatar", {
                  staticClass: "image",
                  attrs: { shape: "circle", size: 85, src: _vm.user_photo },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$router.push("/settings/change-photo")
                    }
                  }
                }),
                _c("div", { staticClass: "info" }, [
                  _c("span", { staticClass: "name" }, [
                    _vm._v(
                      _vm._s(_vm.user.data.first_name) +
                        " " +
                        _vm._s(_vm.user.data.last_name)
                    )
                  ]),
                  _vm.$store.getters.userClaims.email_verified &&
                  _vm.$store.getters.userClaims.phone_verified
                    ? _c(
                        "div",
                        [
                          _c(
                            "span",
                            { staticClass: "verification" },
                            [
                              _c("font-awesome-icon", {
                                staticStyle: { color: "green" },
                                attrs: { icon: ["fas", "check"] }
                              }),
                              _vm._v(" Email & phone verified")
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "edit-link",
                              attrs: { to: "/settings" }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "edit"] }
                              }),
                              _vm._v(" Settings")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.$store.getters.userClaims.email_verified
                    ? _c(
                        "div",
                        [
                          _c(
                            "span",
                            { staticClass: "verification" },
                            [
                              _c("font-awesome-icon", {
                                staticStyle: { color: "green" },
                                attrs: { icon: ["fas", "check"] }
                              }),
                              _vm._v(" Email verified")
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "verification" },
                            [
                              _c("font-awesome-icon", {
                                staticStyle: { color: "red" },
                                attrs: { icon: ["fas", "times"] }
                              }),
                              _vm._v(" Phone not verified")
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "edit-link",
                              attrs: {
                                to:
                                  "/profile-verification?verifications=EMAIL,PHONE,PHOTO&direct=true"
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "info-circle"] }
                              }),
                              _vm._v(" Verify my profile")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.$store.getters.userClaims.phone_verified
                    ? _c(
                        "div",
                        [
                          _c(
                            "span",
                            { staticClass: "verification" },
                            [
                              _c("font-awesome-icon", {
                                staticStyle: { color: "red" },
                                attrs: { icon: ["fas", "times"] }
                              }),
                              _vm._v(" Email not verified")
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "verification" },
                            [
                              _c("font-awesome-icon", {
                                staticStyle: { color: "green" },
                                attrs: { icon: ["fas", "check"] }
                              }),
                              _vm._v(" Phone verified")
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "edit-link",
                              attrs: {
                                to:
                                  "/profile-verification?verifications=EMAIL,PHONE,PHOTO&direct=true"
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "info-circle"] }
                              }),
                              _vm._v(" Verify my profile")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "span",
                            { staticClass: "verification" },
                            [
                              _c("font-awesome-icon", {
                                staticStyle: { color: "red" },
                                attrs: { icon: ["fas", "times"] }
                              }),
                              _vm._v(" Email & phone not verified")
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "edit-link",
                              attrs: {
                                to:
                                  "/profile-verification?verifications=EMAIL,PHONE,PHOTO&direct=true"
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "info-circle"] }
                              }),
                              _vm._v(" Verify my profile")
                            ],
                            1
                          )
                        ],
                        1
                      )
                ])
              ],
              1
            )
          ]),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "commuting-info" },
            [
              _c("span", { staticClass: "small-title" }, [
                _vm._v("Your commuting info")
              ]),
              _c(
                "el-card",
                {
                  attrs: { "body-style": { padding: "10px" }, shadow: "never" }
                },
                [
                  _c("div", { staticClass: "commuting-info-times" }, [
                    _c("div", [
                      _c("span", { staticClass: "info-desc" }, [_vm._v("Go")]),
                      _c("span", { staticClass: "info-data" }, [
                        _vm._v(_vm._s(_vm.go_leaving_time))
                      ])
                    ]),
                    _c("div", [
                      _c("span", { staticClass: "info-desc" }, [
                        _vm._v("Back")
                      ]),
                      _c("span", { staticClass: "info-data" }, [
                        _vm._v(_vm._s(_vm.back_leaving_time))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "commuting-info-days-of-week" }, [
                    _c(
                      "span",
                      {
                        attrs: {
                          "day-active":
                            _vm.user.data.commuting_days_of_week.monday
                        }
                      },
                      [_vm._v("Mon")]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          "day-active":
                            _vm.user.data.commuting_days_of_week.tuesday
                        }
                      },
                      [_vm._v("Tue")]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          "day-active":
                            _vm.user.data.commuting_days_of_week.wednesday
                        }
                      },
                      [_vm._v("Wed")]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          "day-active":
                            _vm.user.data.commuting_days_of_week.thursday
                        }
                      },
                      [_vm._v("Thu")]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          "day-active":
                            _vm.user.data.commuting_days_of_week.friday
                        }
                      },
                      [_vm._v("Fri")]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          "day-active":
                            _vm.user.data.commuting_days_of_week.saturday
                        }
                      },
                      [_vm._v("Sat")]
                    ),
                    _c(
                      "span",
                      {
                        attrs: {
                          "day-active":
                            _vm.user.data.commuting_days_of_week.sunday
                        }
                      },
                      [_vm._v("Sun")]
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "commuting-buddie" }, [
            _c(
              "span",
              {
                staticClass: "small-title",
                staticStyle: { "margin-top": "25px" }
              },
              [_vm._v("Your carpooling connections")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "carpooling-buddies"
              },
              [
                _vm._l(_vm.friends, function(friend) {
                  return _c(
                    "el-card",
                    {
                      key: friend.id,
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { "body-style": { padding: "15px" } },
                      nativeOn: {
                        click: function($event) {
                          return _vm.onClickCarpoolingBuddy(friend.id)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "carpooling-buddy-body" },
                        [
                          _c("el-avatar", {
                            staticClass: "carpooling-buddy-image",
                            attrs: {
                              shape: "circle",
                              size: 55,
                              src: _vm.getUserPhoto(friend.id, 128)
                            }
                          }),
                          _c("div", { staticClass: "carpooling-buddy-name" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(friend.data.first_name) +
                                  " " +
                                  _vm._s(friend.data.last_name)
                              )
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("driver_passenger_text")(
                                    friend.data.carpooling_details
                                  )
                                )
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "carpooling-buddy-action" },
                            [
                              friend.friendship_details == "pending"
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "ellipsis-v"] }
                                  })
                                : _c("font-awesome-icon", {
                                    staticStyle: {
                                      "font-size": "30px",
                                      color: "rgb(79, 206, 93)"
                                    },
                                    attrs: { icon: ["fab", "whatsapp"] },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.sendWhatsapp(
                                          friend.data.phone_number
                                        )
                                      }
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                }),
                _vm.friends.length < 1
                  ? _c(
                      "el-card",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { "body-style": { padding: "15px" } }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "You have no carpooling connections yet. Once you have connections, you will be able to book trips in the schedule."
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      ),
      _c(
        "modal-view",
        {
          attrs: { title: _vm.modal.title },
          model: {
            value: _vm.modal.active,
            callback: function($$v) {
              _vm.$set(_vm.modal, "active", $$v)
            },
            expression: "modal.active"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-option",
              on: { click: _vm.onViewCarpoolingProfile }
            },
            [
              _c(
                "div",
                { staticClass: "modal-option-icon" },
                [_c("font-awesome-icon", { attrs: { icon: ["fas", "eye"] } })],
                1
              ),
              _c("span", { staticClass: "modal-option-label" }, [
                _vm._v("View profile")
              ])
            ]
          ),
          _vm.modal.type == "carpool_connection"
            ? _c(
                "div",
                {
                  staticClass: "modal-option",
                  on: {
                    click: function($event) {
                      return _vm.onClickCarpoolingBuddyOption("delete")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-option-icon" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "times"] }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "modal-option-label" }, [
                    _vm._v("Delete connection")
                  ])
                ]
              )
            : _vm._e(),
          _vm.modal.type == "carpool_request"
            ? _c(
                "div",
                {
                  staticClass: "modal-option",
                  on: {
                    click: function($event) {
                      return _vm.onClickCarpoolingBuddyOption("accept")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-option-icon" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "check"] }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "modal-option-label" }, [
                    _vm._v("Accept connection request")
                  ])
                ]
              )
            : _vm._e(),
          _vm.modal.type == "carpool_request"
            ? _c(
                "div",
                {
                  staticClass: "modal-option",
                  on: {
                    click: function($event) {
                      return _vm.onClickCarpoolingBuddyOption("reject")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-option-icon" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "times"] }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "modal-option-label" }, [
                    _vm._v("Reject connection request")
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }